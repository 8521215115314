<template>
    <div class="contain">
        <searchBar @search="search"></searchBar>
        <div class="title bgf flex">
            <div class="fs_16 color_333 flex_start">
                <span>完税申请</span>
            </div>
            <div class="flex_c">
                <div @click="authPath([], post)" class="btn flex_cen mr_15">
                    <img src="@/assets/imgs/post.png" class="post-icon" alt="" />
                    <span class="fs_14 color">单笔提交完税申请</span>
                </div>
                <div @click="authPath([], postBatch)" class="btn flex_cen">
                    <img src="@/assets/imgs/post1.png" class="post-icon" alt="" />
                    <span class="fs_14 color">批量提交完税申请</span>
                </div>
            </div>
        </div>
        <div class="table bgf mt_10">
            <el-table :data="list" stripe height="56vh" :row-style="rowStyle">
                <el-table-column prop="batch_no" label="批次编号"></el-table-column>
                <el-table-column prop="member_name" label="操作人"></el-table-column>
                <el-table-column prop="status_text" label="待确认 / 待开票 / 已完成 / 开票总数" width="250">
                    <template #default="{ row }">
                        <p class="fs_14">{{ row.tabs_num.status0 }}&nbsp; / &nbsp;{{ row.tabs_num.status1 }}&nbsp; / &nbsp;{{ row.tabs_num.status2 }}&nbsp; / &nbsp;{{ row.tabs_num.all }}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="status_text" label="状态">
                    <template #default="{ row }">
                        <div :class="row.status!=1?'color_f4':'info_color'">
                            {{ row.status_text }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" label="创建时间"></el-table-column>
                <el-table-column prop="final_amount" label="操作">
                    <template #default="{ row, $index }">
                        <div class="flex_cen">
                            <el-button size="mini" type="primary" @click="toDetail(row)">查看详情</el-button>
                            <el-button type="danger" size="mini" :disabled="row.status==1" @click="delRow(row.id, $index)">删除</el-button>
                        </div>
                    </template>
                </el-table-column>
                <template #empty>
                    <empty :finish="finish" :length="list.length"></empty>
                </template>
            </el-table>
            <el-pagination class="page" v-show="list.length!=0" background :page-size="limit" :current-page="page" layout="prev, pager, next" :total="total" @current-change="load">
            </el-pagination>
        </div>
        <agreement ref="agreement" :router="router"></agreement>
    </div>
</template>

<script>
import agreement from './components/agreement'
import { l_getItem } from "@/common/cache"
export default {
	name: 'TaxesList',
	components: { agreement },
	data() {
		return {
			router: '',
			list: [],
			limit: 10,
			page: 1,
			total: 0,
			finish: false,
			keywords: ''
		}
	},
	created() {
		let userInfo = l_getItem("USERINFO") || {}
		let company = l_getItem("COMPANY") || {}
		if (userInfo.company_status && userInfo.company_status !== 'normal' || !company.taxes_agreement) {
			this.$router.replace('/payTaxes/auth')
			return
		}
		this.init()
	},
	methods: {
		init() {
			this.$axios({
				url: `/ent/v3/taxes`,
				method: "GET",
				data: {
					page: this.page,
					limit: this.limit,
					keywords: this.keywords
				},
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.list = res.data.list
					this.total = res.data.total
					this.finish = this.list.length === 0
				} else {
					this.finish = true
				}
			})
		},
		search(v) {
			this.keywords = v
			this.page = 1
			this.list = []
			this.finish = false
			this.init()
		},

		post() {
			this.router = '/payTaxes/add'
			this.$refs.agreement.visible = true
		},
		postBatch() {
			this.router = '/payTaxes/bulkImport'
			this.$refs.agreement.visible = true
		},
		load(page) {
			this.page = page
			this.init()
		},
		toDetail(row) {
			if (row.status == 0) {
				this.$router.push(`/payTaxes/order/${row.id}`)
			} else {
				this.$router.push(`/payTaxes/detail/${row.id}/${row.batch_no}`)
			}
		},
		delRow(id, index) {
			this.$confirm('此操作将删除该批次数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$axios({
					url: `/ent/v3/taxes/${id}`,
					method: "DELETE",
					hideLoading: true
				}).then(res => {
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: '删除成功!'
						})
						this.list.splice(index, 1)
					}
				})

			}).catch(() => {

			})
		}
	}
}
</script>

<style lang='less' scoped>
@color: #1890ff;
@subcolor: #d7deec;
.title {
    padding: 20px 40px 20px 54px;
    border-radius: 2px;

    .post-icon {
        width: 22px;
        height: 22px;
        // margin-right: 10px;
    }
}
.color_f4 {
    color: #ff4e4e;
}
.table {
    min-height: 441px;
    padding-bottom: 20px;
    width: 100%;
    /deep/.el-table {
        thead {
            color: #333;
        }
        .el-table__header-wrapper {
            padding: 6px 0;
            font-size: 14px;
            font-weight: bold;
            border-bottom: 1px solid #f2f2f2 !important;
        }
        &::before {
            height: 0 !important;
        }
        .cell {
            text-align: center !important;
        }
        .el-table__empty-text {
            width: 100%;
        }
    }
    /deep/.el-table--small td,
    /deep/.el-table--small th {
        padding: 10px 0;
        border: none !important;
    }
    .page {
        display: table;
        margin: 0 auto;
        margin-top: 30px;
    }
}
</style>